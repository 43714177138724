// Generated by Framer (2923eb5)

import { addFonts, cx, CycleVariantState, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-jcIuX"

const variantClassNames = {yKax35s2u: "framer-v-zsg3al"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "yKax35s2u", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zsg3al", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yKax35s2u"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(34, 34, 34, 0.12)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7QmUgVmlldG5hbSBQcm8tcmVndWxhcg==", "--framer-font-family": "\"Be Vietnam Pro\", \"Be Vietnam Pro Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-letter-spacing": "0.01em", "--framer-line-height": "1.3em", "--framer-text-color": "var(--extracted-r6o4lv, rgba(0, 0, 0, 0.84))"}}>Linkdlen</motion.p></React.Fragment>} className={"framer-1tuv1c7"} fonts={["FS;Be Vietnam Pro-regular"]} layoutDependency={layoutDependency} layoutId={"MMPQR1g0X"} style={{"--extracted-r6o4lv": "rgba(0, 0, 0, 0.84)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/><SVG className={"framer-d73dvq"} data-framer-name={"Icon"} fill={"black"} intrinsicHeight={32} intrinsicWidth={32} layoutDependency={layoutDependency} layoutId={"bNpRXhSma"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" viewBox=\"0 0 256 256\"><path d=\"M200 64v104a8 8 0 0 1-16 0V83.31L69.66 197.66a8 8 0 0 1-11.32-11.32L172.69 72H88a8 8 0 0 1 0-16h104a8 8 0 0 1 8 8Z\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jcIuX.framer-71w91f, .framer-jcIuX .framer-71w91f { display: block; }", ".framer-jcIuX.framer-zsg3al { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 37px; justify-content: center; overflow: hidden; padding: 12px; position: relative; width: min-content; }", ".framer-jcIuX .framer-1tuv1c7 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-jcIuX .framer-d73dvq { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 18px); position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jcIuX.framer-zsg3al { gap: 0px; } .framer-jcIuX.framer-zsg3al > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-jcIuX.framer-zsg3al > :first-child { margin-left: 0px; } .framer-jcIuX.framer-zsg3al > :last-child { margin-right: 0px; } }", ".framer-jcIuX[data-border=\"true\"]::after, .framer-jcIuX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 37
 * @framerIntrinsicWidth 110
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerzd8NBQv81: React.ComponentType<Props> = withCSS(Component, css, "framer-jcIuX") as typeof Component;
export default Framerzd8NBQv81;

Framerzd8NBQv81.displayName = "Link-card";

Framerzd8NBQv81.defaultProps = {height: 37, width: 110};

addFonts(Framerzd8NBQv81, [{explicitInter: true, fonts: [{family: "Be Vietnam Pro", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/QMIUKUCANYOW3DZTE34ZMVXC7KRYZNCO/X3SZZ4Z5SZNM5K75FPPASQ2YRIJMIPUD/G2K4CBVGEH7VFVIC3T6GBHAWO2E2W7YQ.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})